import React, { Component } from 'react'
import map from 'lodash/map'
import { WidgetsPageLayout } from '../../../components/layouts'
import { withPreview } from '../../../components/common'
import { PreviewHelper } from '../../../helpers'

class WidgetsPTPPagePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            widgetsPage: null,
            sitemap: [],
        }
    }

    componentDidMount() {
        sessionStorage.setItem('preview', true)
        this.props.getPowerToProtectSitemap().then((sitemap) => {
            this.setState({
                sitemap,
            })
        })
        this.props
            .getPowerToProtectEntry('pageWidgetsPTP', this.props.location.search)
            .then((entry) => {
                if (entry) {
                    this.initializeWidgetsPage(entry)
                } else {
                    console.error('Entry is null or undefined')
                }
            })
            .catch((error) => {
                console.error('Error fetching entry:', error)
            })
    }

    componentWillUnmount() {
        sessionStorage.setItem('preview', false)
    }

    initializeWidgetsPage(entry) {
        if (!entry.fields) {
            console.error('Entry fields are null or undefined')
            return
        }

        const sections = entry.fields.sections || []
        const page = {
            __typename: 'ContentfulPageWidgetsPtp',
            ...entry.fields,
            sections: map(sections, (section) => {
                const widgets = section.fields.widgets || []
                return {
                    ...section.fields,
                    widgets: map(widgets, (widget) => PreviewHelper.parse(widget)),
                }
            }),
        }
        this.setState({
            page,
        })
    }

    render() {
        const { page, sitemap } = this.state
        const site = this.props.getSiteInfo()

        return <WidgetsPageLayout className="ptp-root" page={page} site={site} sitemap={sitemap} />
    }
}

export default withPreview(WidgetsPTPPagePreview)
